import { legacy_createStore as createStore } from "redux";
import rootReducer from "./reducer";
// import { initialState as authInitState } from './authReducer'
// import { initialState as commonInitState } from './commonReducer'

const persistedState = localStorage.getItem("reduxState")
  ? JSON.parse(localStorage.getItem("reduxState"))
  : {};

const store = createStore(rootReducer, persistedState);
store.subscribe(() => {
  localStorage.setItem(
    "reduxState",
    JSON.stringify({ ...store.getState().authReducer })
  );
});
export default store;
