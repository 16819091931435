import "./constant";
import { RoleMapping, UserKey, TOKEN, BEARER } from "./constant";
import Cookie from "js-cookie";
import { mapObject } from "../views/common/Common";
const JsonML = require("jsonml.js");

const officeType = [
  "application/pdf",
  "application/msword", // Word 97-2003,
  "application/vnd.ms-powerpoint", // PowerPoint 97-2003
  "application/vnd.ms-excel", // Excel 97-2003
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
];

const pptType = [
  "application/vnd.ms-powerpoint", // PowerPoint 97-2003
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
];
export const getBadge = (key, value) => {
  switch (key) {
    case UserKey.ROLE:
      switch (value) {
        case RoleMapping.SUPER_ADMIN:
          return "danger";
        case RoleMapping.ADMIN:
          return "warning";
        case RoleMapping.TEACHER:
          return "info";
        case RoleMapping.USER:
          return "success";
        default:
          return "success";
      }
    default:
      break;
  }
};

export const isNullOrUndefined = (value) => {
  return value === undefined || value === null;
};

export const isEmpty = (str) => {
  return !str || str.length === 0;
};

export const isAuthenticate = () => {
  return Cookie.get(BEARER);
};
export const isFullAuthenticate = () => {
  return Cookie.get(TOKEN) && Cookie.get(BEARER);
};

export function isDateFormat(date) {
  if (Object.prototype.toString.call(date) === "[object Date]") {
    // it is a date
    if (isNaN(date.getTime())) {
      // d.valueOf() could also work
      // message = "Please complete date of holiday with format dd/mm/yyy";
      return false;
    } else {
      return true;
    }
  } else {
    // message = "Please complete date of holiday with format dd/mm/yyy";
    return false;
  }
}

export function validFileOffice(file) {
  return officeType.includes(file.type);
}

export function validImageNotGif(file) {
  return file.type.includes("image/") && !file.type.includes("/gif");
}
export function validFileImage(file) {
  return file.type.includes("image/");
}

export function validPowerPoint(file) {
  return pptType.includes(file.type);
}

export function returnFileSize(file) {
  if (file.size < 1024) {
    return file.size + "bytes";
  } else if (file.size >= 1024 && file.size < 1048576) {
    return (file.size / 1024).toFixed(1) + "KB";
  } else if (file.size >= 1048576) {
    return (file.size / 1048576).toFixed(1) + "MB";
  }
}

export function validFileExceed(file, size) {
  return file.size < size << 20; // size in MB
}
export function validFileExceed20(file) {
  return file.size < 20971520; // 20 * 1024 * 1024
}

export function validFileExceed10(file) {
  return file.size < 10485760; // 10 * 1024 * 1024
}

export function toCapitalize(str) {
  return str.trim().replace(/^\w/, (c) => c.toUpperCase());
}

export function formatNewsContent(description) {
  let newsContent = "";
  newsContent = JsonML.toHTMLText(JSON.parse(description));
  if (newsContent === "[SyntaxError: invalid JsonML]") {
    newsContent = mapObject(JSON.parse(description));
  }
  return newsContent;
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatNumber(number) {
  if (number >= 1000000000) {
    return (number / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
  }
  if (number >= 1000000) {
    return (number / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (number >= 1000) {
    return (number / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return number;
}
/**
 * 
 * @param {*} myArray Array need check
 * @returns True if myArray dont have similar element
 */
export function isUniqueArray(myArray) {
  return myArray.length === new Set(myArray).size;
}

export function scrollIntoView(value, option) {
  const scrollOption = option || {
    behavior: "smooth",
    block: "end",
    inline: "nearest",
  };
  const htmlElement =
    document.getElementById(value) || document.getElementsByClassName(value)[0];
  htmlElement?.scrollIntoView(scrollOption);
}

export function swap(array, from, to) {
  const template = array[from];
  array[from] = array[to];
  array[to] = template;
  return array;
}

export const currencyFormat = (num) => {
  if (!num) return 0;
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const validateEmail = (email) => {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
};

export const isEditable = (data, currentUser) => {
  switch (currentUser.scopes[0]) {
    case RoleMapping.MB_ADMIN:
      return data.type === "MB_COURSE";
    case RoleMapping.ADMIN:
      return data.createdUserRole === RoleMapping.ADMIN ? currentUser.id === data.createdUserId : data.type === "";
    case RoleMapping.TEACHER:
      return data.type === "" && currentUser.id === data.createdUserId;
    default:
      return true;
  }
}


export const formatMoney = (n, currency) => {
  return Number(n).toFixed(0).replace(/./g, function (c, i, a) {
    return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "." + c : c;
  });
}
