import * as Actions from './Actions'
export const initialState = {
    sidebarShow: 'responsive',
    message: '',
}


export const addToast = (message) => ({
    type: Actions.ADD_TOAST,
    payload: message
});

export const removeToast = () => ({
    type: Actions.REMOVE_TOAST,
});

export default function commonReducer(state = initialState, { type, ...rest }) {
    switch (type) {
        case Actions.SET_SIDEBAR_SHOW:
            return { ...state, ...rest }
        case Actions.ADD_TOAST:
            return { ...state, message: rest.payload }
        case Actions.REMOVE_TOAST:
            return { ...state, message: null }
        default:
            return state;
    }
}