export const TOKEN = "token";
export const BEARER = "bearer";
export const REFRESH_TOKEN = "refresh_token";
export const PREFIX_GOOGLE = "https://docs.google.com/viewer?url=";
export const PREFIX_URL = "https://view.officeapps.live.com/op/embed.aspx?src=";
export const LIMIT2 = 2;
export const LIMIT20 = 20;
export const LIMIT10 = 10;
export const LIMIT100 = 100;
export const MIN_PRICE = 10000;
export const MAX_PRICE = 100000000;
export const EMAIL_REGEX = /^[\w+][\w\.\-]+@[\w\-]+(\.\w{2,10})+$/;
export const PHONE_REGEX = /^(?:[+0]9)?[0-9]{10}$/;
export const NUMBER = /^\d*(\.\d+)?$/;
export const DECIMAL = /[-]?[1-9]+[,.]?[0-9]*([\/][0-9]+[,.]?[0-9]*)*/;
export const URL = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/
export const VALIDATE_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_])[A-Za-z\d@$!%*?&_]{8,}$/
export const Path = {
  STORE_LIST: "stores",
  STORE_USER: "stores_user",
  COINS_LIST: "coins",
  USER_LIST: "users",
  CFIGS_LIST: "systems",
  FEEDBACK_TASK: "feedback_task",
  FEEDBACK_USER: "feedback_user",
  USERS_LIST: "usermanagements",
  TASK_LIST: "tasks",
  APPROVE_PUBLIC: "approve_public",
  INDIVIDUALS_LIST: "individuals",
  MBS_LIST: "mbs",
  PARTNER_LIST: "partner_list",
  USER_VENDOR_LIST: "user_vender_list",
  LIST_COIN: "list_coin",
  BANNER_LIST: "banner_list",
  NEWS_LIST: "news_list",
  USER_REGISTRY: "user_registry",
  ARENA_LIST: "arena_list",
  MANAGE_VENDOR_LIST: "manage_vendor_list",
  PAY_VENDOR_LIST: "pay_vendor_list",
  ROOM_LIST: "room-list",
  END_ROOM_LIST: "end-room",
  ROOM_LC: "room-lc",
  LESSON_MANAGEMENT: "lesson-management",
  //faculty
  FACULTY: "faculty",

  // course
  MB_COURSE: "mb-course",
  ALL_COURSE: "all-course",
  MY_COURSE: "my-course",
  // marketing chanel
  PACKAGE: "package",
  COMBO: "combo",
  MARKETING_FORM: "marketing-form",
  // template slide
  TEMPLATE_SLIDE: "template-slide",
  BUSINESS: "business",
  EDUCATION: "education",
  FINANCE_AND_BANKING: "finance-and-banking",
  TECHNOLOGY: "technology",
  INDUSTRY: "industry",
  MARKETING: "marketing",
  MEDICAL: "medical",
  FOOD: "food",
  REAL_ESTATE: "real-estate",
  RELIGION: "religion",
  SPORT: "sport",
  TRAVEL: "travel",
  // news
  NEWS: "news",
  SOURCE: "source",
  // version
  VERSION: "version",
  BILL: "bill",
};

export const MODAL = {
  ADD: "add",
  EDIT: "edit",
  CONFIRM: "confirm",
  WITHDRAW: "withdraw",
  OPEN: "open",
  PREVIEW: "preview",
};

export const PriorityKey = {
  ON: true,
  OFF: false,
};
export const FilterPriorityOption = [
  { value: "ALL", label: "All" },
  { value: PriorityKey.ON, label: "On" },
  { value: PriorityKey.OFF, label: "Off" },
];

export const ProductKey = {
  COMBO: "COMBO",
  COURSE: "COURSE",
  SLIDE: "SLIDE",
};

export const ProductText = {
  [ProductKey.COMBO]: "Combo",
  [ProductKey.COURSE]: "Khóa học",
  [ProductKey.SLIDE]: "Slide",
};

export const FilterProductOption = [
  { value: "ALL", label: "All" },
  { value: ProductKey.COURSE, label: ProductText[ProductKey.COURSE] },
  { value: ProductKey.SLIDE, label: ProductText[ProductKey.SLIDE] },
  // { value: BillStatusKey.FAIL, label: BillStatusText[BillStatusKey.FAIL] },
];

export const CategoryText = {
  [Path.BUSINESS]: "Business",
  [Path.EDUCATION]: "Education",
  [Path.FINANCE_AND_BANKING]: "Finance and Banking",
  [Path.TECHNOLOGY]: "Technology",
  [Path.INDUSTRY]: "Industry",
  [Path.MARKETING]: "Marketing",
  [Path.MEDICAL]: "Medical",
  [Path.FOOD]: "Food",
  [Path.REAL_ESTATE]: "Real Estate",
  [Path.RELIGION]: "Religion",
  [Path.SPORT]: "Sport",
  [Path.TRAVEL]: "Travel",
};

export const CourseType = {
  SCHOOL: "",
  MB_COURSE: "MB_COURSE",
  PARTNER_COURSE: "PARTNER_COURSE",
};

export const CategoryValue = [
  "Business",
  "Education",
  "Finance and Banking",
  "Technology",
  "Industry",
  "Marketing",
  "Medical",
  "Food",
  "Real Estate",
  "Religion",
  "Sport",
  "Travel",
];

export const RoleMapping = {
  MB_ADMIN: "MB_ADMIN",
  ADMIN: "ADMIN",
  TEACHER: "TEACHER",
  USER: "USER",
  PARTNER_ADMIN: "PARTNER_ADMIN",
};

export const RoleText = {
  [RoleMapping.MB_ADMIN]: "MB Admin",
  [RoleMapping.ADMIN]: "Admin",
  [RoleMapping.TEACHER]: "Teacher",
  [RoleMapping.USER]: "User",
  [RoleMapping.PARTNER_ADMIN]: "Partner Admin",
};

export const RoleMap = {
  ADMIN: "ADMIN",
  ADMIN_LEVEL_1: "ADMIN_LEVEL_1",
  ADMIN_LEVEL_2: "ADMIN_LEVEL_2",
  AD_TEACHER: "AD_TEACHER",
  VENDOR: "VENDOR",
  USER_1: "USER_1",
};

export const TextRole = {
  [RoleMap.ADMIN_LEVEL_2]: "Admin cấp 2",
  [RoleMap.AD_TEACHER]: "Giáo viên",
  [RoleMap.USER_1]: "Sinh viên",
};

export const RoleOptions = [
  { value: "ALL", label: "All" },
  { value: RoleMap.ADMIN_LEVEL_2, label: "Admin cấp 2" },
  { value: RoleMap.AD_TEACHER, label: "Giáo viên" },
  { value: RoleMap.USER_1, label: "Sinh viên " },
];

export const StatusKey = {
  ACTIVE: "ACTIVE",
  PENDING: "PENDING",
  DEACTIVE: "DEACTIVE",
  DISABLE: "DISABLE",
};

export const StatusText = {
  [StatusKey.ACTIVE]: "Active",
  [StatusKey.PENDING]: "Pending",
  [StatusKey.DEACTIVE]: "Inactive",
  [StatusKey.DISABLE]: "Disable",
};

export const StatusValue = [
  { status: StatusKey.DEACTIVE, value: 0 },
  { status: StatusKey.ACTIVE, value: 1 },
  { status: StatusKey.PENDING, value: 2 },
  { status: StatusKey.DISABLE, value: 3 },
];
export const LevelKey = {
  GRADEA: "GRADE A",
  GRADEB: "GRADE B",
  GRADEC: "GRADE C",
  GRADED: "GRADE D",
};

export const LevelText = {
  [LevelKey.GRADEA]: "GRADE A",
  [LevelKey.GRADEB]: "GRADE B",
  [LevelKey.GRADEC]: "GRADE C",
  [LevelKey.GRADED]: "GRADE D",
};

export const LevelValue = [
  { levels: LevelKey.GRADEA, value: 0 },
  { levels: LevelKey.GRADEB, value: 1 },
  { levels: LevelKey.GRADEC, value: 2 },
  { levels: LevelKey.GRADED, value: 3 },
];

export const BannerStatusKey = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
};

export const ArenasStatusKey = {
  AVAILABLE: "AVAILABLE",
  LOCKED: "LOCKED",
};

export const RoleStatusKey = {
  // ADMIN_LEVEL_1: "ADMIN_LEVEL_1",
  ADMIN_LEVEL_2: "ADMIN_LEVEL_2",
  TEACHER: "MODERATOR",
  VENDOR: "VENDOR",
  STUDENT: "SINH VIÊN",
};

export const BillStatusKey = {
  INPROGRESS: "PROCESSING",
  SUCCESS: "SUCCESS",
  FAIL: "FAILURE",
};

export const PartnerStatusKey = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
};

export const PartnerStatusText = {
  [PartnerStatusKey.ACTIVE]: "Active",
  [PartnerStatusKey.INACTIVE]: "Inactive",
};

export const BannerStatusText = {
  [BannerStatusKey.ACTIVE]: "Active",
  [BannerStatusKey.INACTIVE]: "Inactive",
};

export const styleStatus = {
  [BannerStatusKey.ACTIVE]: "info",
  [BannerStatusKey.INACTIVE]: "danger",
};

export const RoleStatusText = {
  [RoleStatusKey.ADMIN_LEVEL_1]: "ADMIN_LEVEL_1",
  [RoleStatusKey.ADMIN_LEVEL_2]: "ADMIN_LEVEL_2",
  [RoleStatusKey.TEACHER]: "MODERATOR",
  [RoleStatusKey.VENDOR]: "VENDOR",
  [RoleStatusKey.STUDENT]: "STUDENT",
};

export const roleStatus = {
  [RoleStatusKey.ADMIN_LEVEL_1]: "ADMIN_LEVEL_1",
  [RoleStatusKey.ADMIN_LEVEL_2]: "ADMIN_LEVEL_2",
  [RoleStatusKey.TEACHER]: "MODERATOR ",
  [RoleStatusKey.VENDOR]: "VENDOR",
  [RoleStatusKey.STUDENT]: "STUDENT",
};

export const ArenasStatusText = {
  [ArenasStatusKey.AVAILABLE]: "Available",
  [ArenasStatusKey.LOCKED]: "Locked",
  [ArenasStatusKey.DONE]: "Done",
};

export const arenasStatus = {
  [ArenasStatusKey.AVAILABLE]: "info",
  [ArenasStatusKey.LOCKED]: "danger",
};

export const UserRegisterStatusKey = {
  SEND: "SEND",
  NEW: "NEW",
};

export const styleUserRegisterStatus = {
  [UserRegisterStatusKey.SEND]: "success",
  [UserRegisterStatusKey.NEW]: "info",
};

export const UserRegisterStatusText = {
  [UserRegisterStatusKey.SEND]: "Đã gửi",
  [UserRegisterStatusKey.NEW]: "Mới",
};

export const SourceKey = {
  WEBSITE: "WEBSITE",
};

export const SourceOptions = [{ value: SourceKey.WEBSITE, label: "Website" }];

export const TopicKey = {
  BANKING: "Banking and Finance",
  PFM: "PFM",
  FINTECH: "Fintech",
};

export const TestKey = {
  TEST: "TEST",
  QUIZ: "QUIZ",
};

export const TestValue = [
  { type: TestKey.QUIZ, value: 0 },
  { type: TestKey.TEST, value: 1 },
];

export const CourseTopic = [
  {
    id: "banking",
    value: "Banking and Finance",
  },
];

export const TopicText = {
  [TopicKey.BANKING]: "Banking and Finance",
  [TopicKey.PFM]: "PFM",
  [TopicKey.FINTECH]: "Fintech",
};

export const TopicValue = [
  { topic: TopicKey.BANKING, value: 0 },
  { topic: TopicKey.PFM, value: 1 },
  { topic: TopicKey.FINTECH, value: 2 },
];
export const UserKey = {
  ID: "id",
  NAME: "name",
  EMAIL: "email",
  SCHOOL_ID: "schoolId",
  ROLE: "role",
  STATUS: "status",
  STUDENT_ID: "studentId",
  SCHOOL_NAME: "schoolName",
  MAJOR_NAME: "majorName",
  CLASS_NAME: "className",
};

export const UserLabel = {
  ID: "Id",
  NAME: "Name",
  EMAIL: "Email Adress",
  SCHOOL_ID: "School Id",
  ROLE: "Role",
  STATUS: "Status",
  STUDENT_ID: "Student Id",
  SCHOOL_NAME: "School Name",
  MAJOR_NAME: "Major",
  CLASS_NAME: "Class",
};

export const UserInfoFields = [
  { key: UserKey.ID, label: UserLabel.ID },
  { key: UserKey.NAME, label: UserLabel.NAME },
  { key: UserKey.EMAIL, label: UserLabel.EMAIL },
  { key: UserKey.SCHOOL_ID, label: UserLabel.SCHOOL_ID },
  { key: UserKey.ROLE, label: UserLabel.ROLE },
  { key: UserKey.STATUS, label: UserLabel.STATUS },
  { key: UserKey.STUDENT_ID, label: UserLabel.STUDENT_ID },
  { key: UserKey.SCHOOL_NAME, label: UserLabel.SCHOOL_NAME },
  { key: UserKey.MAJOR_NAME, label: UserLabel.MAJOR_NAME },
  { key: UserKey.CLASS_NAME, label: UserLabel.CLASS_NAME },
];

export const FilterRoleOptions = [
  { value: "ALL", label: "All" },
  { value: RoleMapping.MB_ADMIN, label: "MB Admin" },
  { value: RoleMapping.ADMIN, label: "Admin" },
  { value: RoleMapping.PARTNER_ADMIN, label: "Partner Admin" },
  { value: RoleMapping.TEACHER, label: "Teacher" },
  { value: RoleMapping.USER, label: "User" },
];

export const FilterStatusOptions = [
  { value: "ALL", label: "All" },
  { value: StatusKey.ACTIVE, label: "Active" },
  { value: StatusKey.PENDING, label: "Pending" },
  { value: StatusKey.DEACTIVE, label: "Inactive" },
];

export const FilterCourseStatusOptions = [
  { value: "ALL", label: "Tất cả" },
  { value: StatusKey.ACTIVE, label: "Đang thực hiện" },
  { value: StatusKey.PENDING, label: "Hoàn thành" },
  { value: StatusKey.DEACTIVE, label: "Đang chờ duyệt" },
  // { value: StatusKey.DISABLE, label: "Disable" }
];

export const FilterUserRegisterStatusOptions = [
  { value: "ALL", label: "All" },
  { value: UserRegisterStatusKey.SEND, label: "Đã gửi" },
  { value: UserRegisterStatusKey.NEW, label: "Mới" },
];

export const FilterSlideStatusOptions = [
  { value: "ALL", label: "All" },
  { value: StatusKey.ACTIVE, label: "Active" },
  { value: StatusKey.DEACTIVE, label: "Inactive" },
];

export const FilterBannerStatusOption = [
  { value: "ALL", label: "All" },
  { value: BannerStatusKey.ACTIVE, label: "Active" },
  { value: BannerStatusKey.INACTIVE, label: "Inactive" },
];

export const FilterRoleStatusOption = [
  { value: "ALL", label: "All" },
  { value: RoleStatusKey.ADMIN_LEVEL_2, label: "ADMIN_LEVEL_2" },
  { value: RoleStatusKey.TEACHER, label: "MODERATOR" },
  { value: RoleStatusKey.VENDOR, label: "VENDOR" },
  { value: RoleStatusKey.STUDENT, label: "STUDENT" },
];

export const FilterArenasStatusOption = [
  { value: "ALL", label: "All" },
  { value: BannerStatusKey.AVAILABLE, label: "AVAILABLE" },
  { value: BannerStatusKey.LOCKED, label: "LOCKED" },
  { value: BannerStatusKey.DONE, label: "DONE" },
];

export const FilterPartnerStatusOption = [
  { value: "ALL", label: "All" },
  { value: PartnerStatusKey.ACTIVE, label: "Active" },
  { value: PartnerStatusKey.INACTIVE, label: "Inactive" },
];

export const FilterNewsTypeOptions = [
  { value: "ALL", label: "All" },
  { value: "USER_ENTERED", label: "Tự tạo" },
  { value: "WEBSITE", label: "Craw" },
];

export const FilterCourseTopicOptions = [
  { value: "ALL", label: "All" },
  { value: TopicKey.BANKING, label: "Banking and Finance" },
  { value: TopicKey.PFM, label: "PFM" },
  { value: TopicKey.FINTECH, label: "Fintech" },
];

export const FilterPartnerService = [
  { value: "ALL", label: "All" },
  { value: "Banner quảng cáo", label: "Banner quảng cáo" },
];

export const FilterQuizOptions = [
  { value: "ALL", label: "All" },
  { value: TestKey.TEST, label: "Test" },
  { value: TestKey.QUIZ, label: "Quiz" },
];

export const FilterYNOptions = [
  { value: "ALL", label: "All" },
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

export const FilterNewsSourceStatusOptions = [
  { value: "ALL", label: "All" },
  { value: StatusKey.ACTIVE, label: "Active" },
  { value: StatusKey.DEACTIVE, label: "Inactive" },
];

export const FilterSourceAutoPostOptions = [
  { value: "ALL", label: "All" },
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

export const FilterSourceTopicOptions = [
  { value: "ALL", label: "All" },
  { value: TopicKey.BANKING, label: "Banking and Finance" },
  { value: TopicKey.PFM, label: "PFM" },
  { value: TopicKey.FINTECH, label: "Fintech" },
];

export const RoleRoom = {
  DONE: "Done",
};

export const roleRoom = {
  [RoleStatusKey.DONE]: "Done",
};

export const FilterRoleRoom = [{ value: RoleRoom.DONE, label: "Done" }];

//step5-lc && preview lc
export const columns = [
  {
    title: 'Loại chứng từ',
    dataIndex: 'typeOfDocument',
    key: 'typeOfDocument',
  },
  {
    title: 'Số lượng bản chính',
    dataIndex: 'originals',
    key: 'originals',
  },
  {
    title: 'Số lượng bản sao',
    dataIndex: 'copies',
    key: 'copies',
  },
];

//form1-3 - lc 
export const LCtype = ['Irrevocable', 'Revocable', 'Irrevocable transferable', ' Revocable transferable', 'Irrevocable standby', 'Revocable standby', 'Irrevoc trans standby']
export const LCtypeSend = ["IRREVOCABLE", "REVOCABLE", "IRREVOCABLE_TRANSFERABLE", "REVOCABLE_TRANSFERABLE", "IRREVOCABLE_STANDBY", "REVOCABLE_STANDBY", "IRREVOC_TRANS_STANDBY"]
export const AdvisingBank = ["TPBank – TPBVVNVX", "BIDV -  BIDVVNVX", "Techcombank – VTCBVNVX", "Vietcombank – BFTVVNVX", "Vietinbank - ICBVVNVX"]
export const AdBankSend = ["TPBANK", "BIDV", "TECHCOMBANK", "VIETCOMBANK", "VIETINBANK"]
export const AvailableWith = ["MBBank - MSCBVNVX", "TPBank – TPBVVNVX", "BIDV -  BIDVVNVX", "Techcombank – VTCBVNVX", "Vietcombank – BFTVVNVX", "Vietinbank - ICBVVNVX"]
export const AvailableWithSend = ["MBBANK", "TPBANK", "BIDV", "TECHCOMBANK", "VIETCOMBANK", "VIETINBANK"]
export const ByData = ["Money", "Payment at sight", "Negotiation", "Acceptance", "Commitment"]
export const ByDataSend = ["MONEY", "PAYMENT_AT_SIGHT", "NEGOTIATION", "ACCEPTANCE", "COMMITMENT"]
export const Swift = ['TPBVVNVX', "BIDVVNVX", "VTCBVNVX", "BFTVVNVX", "ICBVVNVX"]
