import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { icons } from './assets/icons'

import { Provider } from 'react-redux'
import store from './store'
import Toast from './components/toast/Toast';
import { IntlProvider, addLocaleData } from "react-intl";
import en from "react-intl/locale-data/en";
import vi from "react-intl/locale-data/vi";
import viMessages from './utils/locales/vi.json'
// import enMessages from './utils/locales/en.json'
addLocaleData([...en, ...vi]);
React.icons = icons

ReactDOM.render(
  <Provider store={store}>
    <IntlProvider locale={'vi'} messages={viMessages}>
      <>
        <App />
        <Toast />
      </>
    </IntlProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
