import React from 'react'
import { connect } from 'react-redux'
import { removeToast } from '../../store/commonReducer'
import './style.scss'

class Toast extends React.PureComponent {

    componentDidUpdate() {
        if (this.props.message) {
            setTimeout(() => {
                this.props.removeToast()
            }, 5000);
        }
    }

    render() {
        const { message } = this.props;
        return (
            <div className={`toast${message ? ' show' : ''}`}>
                {message}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return ({
        message: state.commonReducer.message
    })
};

const mapDispatchToProp = {
    removeToast
}
export default connect(mapStateToProps, mapDispatchToProp)(Toast)