import React, { Component } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import "./scss/style.scss";
import { isFullAuthenticate } from "./utils/helper";
import { addToast } from "src/store/commonReducer";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const ForgetPassword = React.lazy(() =>
  import("./views/pages/login/ForgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("./views/pages/login/ResetPassword")
);
const PageForbidden = React.lazy(() =>
  import("./views/pages/restrict/PageForbidden")
);
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

class App extends Component {
  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => {
                if (isFullAuthenticate()) {
                  return <Redirect to={{ pathname: "/", state: {} }} />;
                }
                return <Login {...props} />;
              }}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/forgot-password"
              name="Forgot Password Page"
              render={(props) => <ForgetPassword {...props} />}
            />
            <Route
              exact
              path="/reset-password/:id"
              name="Reset Password Page"
              render={(props) => <ResetPassword {...props} />}
            />
            <Route
              exact
              path="/403"
              name="Forbidden"
              render={(props) => <PageForbidden {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => {
                if (!isFullAuthenticate()) {
                  if (!isFullAuthenticate() && this.props?.users?.length > 0) {
                    this.props.addToast("Hết phiên đăng nhập, vui lòng đăng nhập lại")
                  }
                  // Move to login page if token expire
                  return <Redirect to={{ pathname: "/login", state: {} }} />;
                }
                return <TheLayout {...props} />;
              }}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}
const mapDispatchToProps = {
  addToast,
};
const mapStateToProps = (state) => {
  return {
    users: state.authReducer?.currentUser?.email
  };
};
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(App));
