import * as Actions from "./Actions";
export const initialState = {
  currentUser: localStorage.getItem("reduxState")
    ? JSON.parse(localStorage.getItem("reduxState")).currentUser
    : null,
};

export const updateCurrentUser = (newCurrentUser) => ({
  type: Actions.UPDATE_CURRENT_USER,
  payload: newCurrentUser,
});

export default function authReducer(state = initialState, { type, ...rest }) {
  switch (type) {
    case Actions.UPDATE_CURRENT_USER:
      return { ...state, currentUser: rest.payload };
    default:
      return state;
  }
}
